import styled from 'styled-components';
import media from '../../../common/MediaQueries';
import Typography from '../../styles/Typography';

export const AuthorContainer = styled(Typography)`
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: 0.875rem;
`;

export const Author = styled(Typography).attrs({ forwardedAs: 'span' })`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export const ListingWrapper = styled.div`
  margin: 4rem 0;

  @media ${media.desktop} {
    margin: 7.5rem 0;
  }
`;
