import { useMemo } from 'react';

export const useNav = (
  allCategories: GatsbyTypes.CategoryQueryQuery['allSanityCategory'],
  listingLink: string
) =>
  useMemo(() => {
    return [
      { key: '123', label: 'ALL STORIES', link: { to: listingLink }, isActive: true },
      ...allCategories?.nodes?.map(({ _id, name, slug }) => ({
        key: _id || '',
        label: name || '',
        link: { to: slug?.current || '' },
        isActive: false,
      })),
    ];
  }, [allCategories, listingLink]);
