import { graphql } from 'gatsby';
import React from 'react';

import Sections from '../../sections';
import StoriesListing from '../../sections/StoriesListing';
import { useNav } from './hooks';
import { BlogListingProps } from './types';
import * as Styled from './styles';
import HeroWithPosts from '../../organisms/HeroWithPosts';
import { getImage } from '../../../utils/sanityTypes';
import { Helmet } from 'react-helmet';

const BlogListing: React.VFC<BlogListingProps> = ({
  data: { sanityBlogListingPage, allSanityCategory },
}) => {
  const {
    sections = [],
    title,
    subtitle,
    buttonText,
    slug,
    featuredBlogs,
    scrollButtonLabel,
    teaserButtonLabel,
  } = sanityBlogListingPage ?? {};

  const nav = useNav(allSanityCategory, slug?.current || '');

  return (
    <>
    <Helmet
      htmlAttributes={{
        lang: sanityBlogListingPage?.i18n_lang
      }}
      title={sanityBlogListingPage?.seo?.title}
      titleTemplate={'%s - Lyngen North'}
      defaultTitle="Glass igloo hotel - Lyngen North"
    >
        {sanityBlogListingPage?.seo?.title &&
          <meta property="og:title" content={sanityBlogListingPage.seo.title} />
        }
        {sanityBlogListingPage?.seo?.keywords &&
          <meta name="keywords" content={sanityBlogListingPage.seo.keywords} />
        }
        {sanityBlogListingPage?.seo?.description &&
          <meta property="og:description" content={sanityBlogListingPage.seo.description} />
        }
        {sanityBlogListingPage?.seo?.description &&
          <meta name="description" content={sanityBlogListingPage.seo.description} />
        }
        {sanityBlogListingPage?.seo?.image?.asset?.url &&
          <meta property="og:image" content={sanityBlogListingPage.seo.image.asset.url} />
        }
        {sanityBlogListingPage?.seo?.image?.alt && sanityBlogListingPage?.seo?.image?.asset?.url &&
          <meta property="og:image:alt" content={sanityBlogListingPage.seo.image.alt} />
        }
      </Helmet>
      <HeroWithPosts
        data={{
          posts:
            featuredBlogs?.map((post) => {
              const {
                _id,
                title: postTitle,
                subtitle: postSubtitle,
                firstName,
                lastName,
                publishDate,
                slug: postSlug,
                images,
              } = post || {};
              return {
                key: _id || '',
                title: postTitle || '',
                subtitle: postSubtitle || '',
                firstName: firstName || '',
                lastName: lastName || '',
                publishDate: publishDate || '',
                link: { to: postSlug?.current || '' },
                images: (images ?? []).map((slide) => {
                  const { mobileImage, image, _key: key } = slide || {};

                  return {
                    key: key ?? '',
                    image: getImage(image),
                    mobileImage: getImage(mobileImage),
                  };
                }),
              };
            }) || [],
          scrollButtonLabel: scrollButtonLabel || '',
          teaserButtonLabel: teaserButtonLabel || '',
        }}
      />
      <Styled.ListingWrapper id="list">
        <StoriesListing
          data={{
            title: title || '',
            subtitle,
            buttonLabel: buttonText || '',
            nav,
          }}
        />
      </Styled.ListingWrapper>
      <Sections {...{ sections }} />
    </>
  );
};

export default BlogListing;

export const blogListingQuery = graphql`
  query ListingQuery($language: String!) {
    sanityBlogListingPage(i18n_lang: { eq: $language }) {
      _id
      i18n_lang
      slug {
        current
      }
      title
      subtitle
      seo {
        title
        description
        keywords
        image {
          alt
          asset {
            url
          }
        }
      }
      buttonText
      featuredBlogs {
        _id
        title
        slug {
          current
        }
        subtitle
        images {
          _key
          mobileImage {
            ...ImageFragment
          }
          image {
            ...ImageFragment
          }
        }
        firstName
        lastName
        publishDate(formatString: "MMMM d, yyyy")
      }
      teaserButtonLabel
      scrollButtonLabel
      sections {
        ... on SanityHeroBaseSection {
          ...HeroBaseSectionFragment
        }
        ... on SanityActivityBookingSection {
          ...ActivityBookingSectionFragment
        }
        ... on SanityHeroWithTabsSection {
          ...HeroWithTabsSectionFragment
        }
        ... on SanityHeroWithContactFormSection {
          ...HeroWithContactFormSectionFragment
        }
        ... on SanityHotelsSection {
          ...HotelsSectionFragment
        }
        ... on SanityTilesSection {
          ...TilesSectionFragment
        }
        ... on SanityHotelsTabsSection {
          ...HotelsTabsSectionFragment
        }
        ... on SanityReviewsCarouselSection {
          ...ReviewsCarouselSectionFragment
        }
        ... on SanityTeamSection {
          ...TeamSectionFragment
        }
        ... on SanityTitleFaqSection {
          ...TitleFaqSectionFragment
        }
        ... on SanityBookNowSection {
          ...BookNowSectionFragment
        }
        ... on SanityVideoBoxSection {
          ...VideoBoxSectionFragment
        }
        ... on SanityWideBannerSection {
          ...WideBannerSectionFragment
        }
        ... on SanityAboutUsSection {
          ...AboutUsSectionFragment
        }
        ... on SanityTextSection {
          ...TextSectionFragment
        }
        ... on SanityTextListSection {
          ...TextListSectionFragment
        }
        ... on SanityTransportScheduleSection {
          ...TransportScheduleSectionFragment
        }
        ... on SanityTransportOptionsSection {
          ...TransportOptionsSectionFragment
        }
        ... on SanityHotelWithFeaturesListSection {
          ...HotelWithFeaturesListSectionFragment
        }
        ... on SanityLocationSection {
          ...LocationSectionFragment
        }
        ... on SanityPostTeasersSection {
          ...PostTeasersSectionFragment
        }
        ... on SanityImagesGallerySection {
          ...ImagesGallerySectionFragment
        }
        ... on SanitySocialIconsSection {
          ...SocialIconsSectionFragment
        }
      }
    }

    sanityFooter(i18n_lang: { eq: $language }) {
      ...FooterFragment
    }

    sanityHeader(i18n_lang: { eq: $language }) {
      ...HeaderFragment
    }

    sanityCookieInfo(i18n_lang: { eq: $language }) {
      ...CookieInfoFragment
    }

    sanityPromoBanner(i18n_lang: { eq: $language }) {
      ...PromoBannerFragment
    }

    allSanityCategory(filter: { i18n_lang: { eq: $language } }) {
      nodes {
        _id
        name
        slug {
          current
        }
      }
    }
  }
`;
